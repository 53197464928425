@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-family: 'Lato', sans-serif;
    font-family: 'Montserrat Alternates', sans-serif;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Roboto', sans-serif; */
    }
    