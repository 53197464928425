@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  font-family: 'IBM Plex Sans Condensed', sans-serif !important;
  font-family: 'Lato', sans-serif !important;
  font-family: 'Montserrat Alternates', sans-serif !important;
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'Roboto', sans-serif; */
  }
  

    


.herox{
    position: relative;
    background-image: url("https://media.istockphoto.com/id/1344688192/photo/shot-of-a-young-man-using-his-digital-tablet-and-computer-in-a-modern-office.jpg?s=612x612&w=0&k=20&c=6h8BU5yqJDSuW9LS7SdoqYTipap8MvcZNiR9cvBaC-I=");
    background-size: cover;
    background-position: center;
    height: fit-content; 
  }
  
  .herox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .heroxx{
    position: relative;
    background-image: url("https://www.cyberdegrees.org/wp-content/uploads/2021/12/Women_In_Cyber.jpg");
    background-size: cover;
    background-position: center;
    height: fit-content; 
  }
  
  .heroxx::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }


  .get__one{
    background-color: #FF0102;
    padding-left: 20px;
    padding-right: 20px;
    height: fit-content;
    margin-top: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 10px;
  }
  
  .get__two{
    background-color: #fefeff;
    padding-left: 20px;
    padding-right: 20px;
    height: fit-content;
    margin-top: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 10px;
    color: black;
  }


  .numbers{
    position: relative;
    background-image: url("https://as2.ftcdn.net/v2/jpg/05/89/48/55/1000_F_589485529_zvF4n6KB0XBnihqNBNqxYiUa4r3s4t6Z.jpg");
    background-size: cover;
    background-position: center;
    height: fit-content; 
  }
  
  .numbers::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .bottomx{
    border-bottom: solid 4px #FF0102;
  }

  .avatarxx{
    border: solid 1px #FF0102;
  }

  .law_shadow{
    box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
    /* border-bottom: #FF0102 solid 2px; */
    border-radius: 10px;
  }
  .serv__box{
    box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
    border-bottom: #FF0102 solid 2px;
  }

  .learn__x{
    border: solid #FF0102 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    transition: 350ms ease-in-out;
  }

.learn__x:hover{
    background-color: #FF0102;
    color: white;
}

.learn__x1{
  background-color: #FF0102;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  transition: 350ms ease-in-out;
  border: #FF0102 solid 1px;
  color: white;
}

.learn__x1:hover{
  background-color: white;
  color: #FF0102;
 
}



.review__bord{
  border-bottom: #FF0102 solid 3px;
}


.Contact__con{
  position: relative;
  background-image: url('https://d1e00ek4ebabms.cloudfront.net/production/60770aa4-dc17-471a-a0a3-47730dec2b65.jpg');
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Contact__con::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}


.buttonx{
  background-color: #FF0102;
  margin-right: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  border-radius: 20px;
}

.Bitcoin{
  position: relative;
  background-image: url("https://www.usnews.com/object/image/00000181-3fa3-dc82-abd7-7faf9a450001/gettyimages-1397862835.jpg?update-time=1654630095236&size=responsive640");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Bitcoin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}

.Blackmail{
  position: relative;
  background-image: url("https://img.freepik.com/premium-photo/sad-unhappy-grieving-crying-woman-with-tears-eyes_122732-2277.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Blackmail::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}


.Accoount{
  position: relative;
  background-image: url("https://ead.umc.br/hs-fs/hubfs/hacker-do-bem.webp?width=1200&name=hacker-do-bem.webp");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Accoount::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}

.Romance{
  position: relative;
  background-image: url("https://imgix.bustle.com/uploads/shutterstock/2021/6/22/5f25eab8-9614-4d99-9bc9-9d881eaa20a1-shutterstock-1639121464.jpg?w=1200&h=630&fit=crop&crop=faces&fm=jpg");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Romance::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Phone{
  position: relative;
  background-image: url("https://cdn.idropnews.com/wp-content/uploads/2022/02/17110324/MacBook-Pro-Coding-or-Hacking.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Phone::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}


.Social{
  position: relative;
  background-image: url("https://img.freepik.com/premium-photo/background-image-several-computer-screens-with-code-lines-desk-it-developers-office-copy-space_236854-29618.jpg?w=900");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Social::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.About{
  position: relative;
  background-image: url("https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHRlY2glMjBvZmZpY2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.About::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Contact{
  position: relative;
  background-image: url("https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHRlY2glMjBvZmZpY2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.Contact::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}



@keyframes beep {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply the animation to the logo when it has the 'loading' class */
.logo.loading {
  animation: beep 0.8s infinite; /* Adjust the animation duration as needed */
}

.the_law{
  position: relative;
  background-image: url("https://cdn-eefoi.nitrocdn.com/GMjjhgHprMTTKlOFpthkOtoWVGprBkXZ/assets/images/optimized/rev-59518f4/resultsyoudeserve.com/wp-content/uploads/2022/03/KBG-Attorneys-updatre.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.the_law::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
